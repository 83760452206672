import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Hi, I'm Sam and I am pursuing Computer Science.
          </p>
          Sorry for improper portfolio website 
          <p>
        </p>
        <a
          className="App-link"
          href="https://drive.google.com/file/d/1rSPrXUvmiL13_o0yZ8cDpKcN7QyltnbW/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          Link to resume
        </a>
      </header>
    </div>
  );
}

export default App;
